//-------------------------------------------------
//		youtube playlist jquery plugin
//		Created by dan@geckonm.com
//		www.geckonewmedia.com
//
//		v1.1 - updated to allow fullscreen 
//			 - thanks Ashraf for the request
//-------------------------------------------------
//modified by Geonetric to include iframe instead of old embed code.

jQuery.fn.ytplaylist = function (options) {

    // default settings
    var options = jQuery.extend({
        holderId: 'yt_holder',
        playerHeight: '300',
        playerWidth: '450',
        addThumbs: false,
        thumbSize: 'small',
        showInline: false,
        autoPlay: false,
        showRelated: false,
        allowFullScreen: true
    }, options);

    return this.each(function () {

        var selector = $(this);

        var autoPlay = "";
        var showRelated = "?rel=0";
        var fullScreen = "";
        if (options.autoPlay) autoPlay = "&autoplay=1";
        if (options.showRelated) showRelated = "?rel=1";
        if (options.allowFullScreen) fullScreen = "allowfullscreen";

        //throw a youtube player in
        function play(id) {
            var html = '';

            html += '<iframe height="' + options.playerHeight + '" width="' + options.playerWidth + '" frameborder="0" src="https://www.youtube-nocookie.com/embed/' + id + showRelated + autoPlay + '" ' + fullScreen + '></iframe>';

            return html;

        };


        //grab a youtube id from a (clean, no querystring) url (thanks to http://jquery-howto.blogspot.com/2009/05/jyoutube-jquery-youtube-thumbnail.html)
        function youtubeid(url) {
            var ytid = url.match("[\\?&]v=([^&#]*)");
            ytid = ytid[1];
            return ytid;
        };


        //load inital video
        var firstVid = selector.children("li:first-child").addClass("currentvideo").children("a").attr("href");
        $("#" + options.holderId + "").html(play(youtubeid(firstVid)));

        //load video on request
        selector.children("li").children("a").click(function () {

            if (options.showInline) {
                $("li.currentvideo").removeClass("currentvideo");
                $(this).parent("li").addClass("currentvideo").html(play(youtubeid($(this).attr("href"))));
            }
            else {
                $("#" + options.holderId + "").html(play(youtubeid($(this).attr("href"))));
                $(this).parent().parent("ul").find("li.currentvideo").removeClass("currentvideo");
                $(this).parent("li").addClass("currentvideo");
            }



            return false;
        });

        //do we want thumbs with that?
        if (options.addThumbs) {

            selector.children().each(function (i) {

                var replacedText = $(this).text();

                if (options.thumbSize == 'small') {
                    var thumbUrl = "https://img.youtube.com/vi/" + youtubeid($(this).children("a").attr("href")) + "/2.jpg";
                }
                else {
                    var thumbUrl = "https://img.youtube.com/vi/" + youtubeid($(this).children("a").attr("href")) + "/0.jpg";
                }


                $(this).children("a").empty().html("<img src='" + thumbUrl + "' alt='" + replacedText + "' />" + replacedText).attr("title", replacedText);

            });

        }



    });

};